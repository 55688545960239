import { useState, useEffect } from "react";

import { useGame } from "../../../../hooks/useGame";
import { SelectionItem } from "../../Items/SelectionItem";
import { PlayersQueueCounter } from "../../PlayersQueueCounter";

export const SelectionStage = ({
  setSelectionTypeSelection,
  handleMatchButton,
}) => {
  const { selectedCards } = useGame();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000);
  }, [loading]);

  return (
    <div className="relative flex min-w-[300px] flex-col items-center justify-center gap-6">
      <p className="text-center">Select your cards. You must include a Pet.</p>
      <PlayersQueueCounter />

      <div className="flex gap-4 ">
        {selectedCards &&
          Object.entries(selectedCards).map((card: any, _: number) => {
            return (
              <div
                key={_}
                className="flex flex-col items-center justify-center gap-2 md:flex-row"
              >
                <div
                  onClick={() => setSelectionTypeSelection(card[0])}
                  className="flex min-h-[160px] min-w-[80px] cursor-pointer flex-col items-center justify-center rounded-md bg-[#00000051] px-0 shadow-2xl transition-all hover:bg-[#00000079] md:h-[300px] md:w-[200px] md:px-4"
                >
                  {card[1] ? (
                    <SelectionItem
                      item={card[1]}
                      type={() => null}
                      handleSelectCard={() => null}
                    />
                  ) : (
                    <>
                      <div className="flex flex-1 items-center justify-center">
                        <div className="m-auto flex h-[20px] w-[20px] items-center justify-center rounded-full bg-black text-center text-[18px]">
                          &#43;
                        </div>
                      </div>
                      <div>
                        {
                          {
                            pet: "Pet Slot",
                            cannon: "Cannon Slot",
                            boost: "Amulet Slot",
                          }[card[0]]
                        }
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          })}
      </div>
      <span className="">
        <a className="inventory-a">
          <div
            className="gamebtn flex h-[50px] w-[165px] items-center justify-center whitespace-nowrap transition-all hover:scale-110"
            onClick={() =>
              loading ? null : setLoading(true) + handleMatchButton("find")
            }
          >
            {loading ? (
              <div className="loading-circle !h-[15px] !w-[15px] after:hidden"></div>
            ) : (
              "Find Match"
            )}
          </div>
        </a>
      </span>
    </div>
  );
};
