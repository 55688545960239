import { useEffect } from "react";

import { Game, IRound, PlayerOne } from "../../../@types/socket";

export const CountWins = ({
  rounds,
  user,
  gameData,
}: {
  rounds: IRound[];
  user: PlayerOne;
  gameData: Game | null;
}) => {
  // isDraw can be null so we need to check if it`s true/false
  const roundsWithWinner = rounds
    ?.filter(
      item =>
        (item.winnerId !== null && !item.isDraw) ||
        (item.isDraw !== null && item.isDraw && !item.winnerId),
    )
    .sort((a, b) => a.round - b.round)
    .map(round => {
      if (round.round === 3) {
        return {
          ...round,
          winnerId: round.winnerId || gameData?.winnerId,
          isDraw: round.isDraw || gameData?.isDraw,
        };
      }
      return round;
    });

  // useEffect(() => {
  //   console.log(user?.deck?.playerId, {
  //     rounds,
  //     gameData,
  //   });
  // }, [rounds]);

  const getResultIcon = round => {
    if (round.winnerId !== null) {
      return round?.winnerId === user?.deck?.playerId ? "✅" : "❌";
    }

    if (round.isDraw) {
      return "🤝";
    }

    return "";
  };

  return (
    <div className={`flex`}>
      {roundsWithWinner?.map((item, index) => (
        <span key={index} className={`text-base`}>
          {getResultIcon(item)}
        </span>
      ))}
    </div>
  );
};
