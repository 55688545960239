import { Dispatch, SetStateAction } from "react";
import { FiAlertOctagon } from "react-icons/fi";

import Modal from "../Modal";

interface Props {
  fn: (() => void) | null;
  setFn: Dispatch<SetStateAction<(() => void) | null>>;
  btnText: string;
  title: string;
  cancelBtn?: boolean;
}

export const ConfimationModal = ({
  fn,
  setFn,
  btnText,
  title,
  cancelBtn = false,
}: Props) => {
  const setIsOpen = (action: boolean) => {
    return action ? null : setFn(null);
  };

  return (
    <Modal isOpen={!!fn} setIsOpen={setIsOpen}>
      <div className="flex flex-col items-center justify-center">
        <div>
          <FiAlertOctagon className="h-[128px] w-[128px] text-center text-amber-400" />
        </div>
        <div>
          <p className="w-[300px] text-center text-lg">{title}</p>
        </div>
        <div className="mt-2 flex gap-4">
          <button className="gamebtn" onClick={() => (fn ? fn() : () => null)}>
            {btnText}
          </button>
          {cancelBtn && (
            <button className="gamebtn" onClick={() => setFn(null)}>
              Cancel
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};
