import { useEffect, useState } from "react";
import { FaHeart } from "react-icons/fa";
import { FaDroplet } from "react-icons/fa6";

import { ITurn, PlayerOne } from "../../../../@types/socket";
import { useBattle } from "../../../../hooks/useBattle";
import { useDamage } from "../../../../hooks/useDamage";
import { ActionsBattleButtonsMobile } from "../../ActionsBattleButtons";
import {
  PlayerDetails,
  PlayerDetailsMobile,
} from "../../GameStages/PlayerDetails";
import { AtributeBar } from "../AtributeBar";

type Props = {
  player: PlayerOne;
  opponent: PlayerOne;
  isUser?: boolean;
  prevTurn?: ITurn | undefined;
};
type PropsMobile = Props & {
  buttonAction?: (action: string) => void;
  disabledButtons?: boolean;
  setDisabledButtons?: (value: boolean) => void;
};

export const PlayersAttributes = ({
  player,
  opponent,
  isUser = false,
  prevTurn,
}: Props) => {
  const { damageTaken } = useDamage({ prevTurn, player });
  return (
    <>
      <section className=" flex w-full justify-center gap-8 xl:justify-between">
        <aside
          className={`relative flex h-max w-[250px] flex-col gap-4 rounded-lg bg-[#121212c8] p-4 text-center `}
        >
          <span className={`${isUser ? "" : " text-red-600 "}`}>
            {isUser ? "My" : "Opponent's"} HP
          </span>
          <AtributeBar
            maxAmount={player?.details?.pet?.totalHp || 0}
            currentAmount={player?.details?.pet?.currentHp || 0}
            color="#2ecc71"
          />{" "}
          <span className="text-center text-sm ">wallet: {player.player}</span>
        </aside>

        <div className="hidden lg:block">
          {player && (
            <PlayerDetails
              user={player}
              opponent={opponent}
              damageTaken={damageTaken}
              haveCannon={Boolean(
                player?.details?.cannon?.strength ||
                  player?.details?.cannon?.cannon_damage,
              )}
            />
          )}
        </div>

        <aside
          className={` flex h-max w-[250px]  flex-col gap-4 rounded-lg bg-[#121212c8] p-4 text-center `}
        >
          <span className={`${isUser ? "" : " text-red-600 "}`}>
            {isUser ? "My" : "Opponent's"} Mana
          </span>
          <AtributeBar
            maxAmount={player?.details?.pet?.manaMax || 0}
            currentAmount={player?.details?.pet?.currentMana || 0}
            color="#0984e3"
          />
        </aside>
      </section>
    </>
  );
};

export const PlayersAttributesMobile = ({
  player,
  opponent,
  isUser = false,
  buttonAction,
  disabledButtons,
  setDisabledButtons,
  prevTurn,
}: PropsMobile) => {
  const { activeTurn } = useBattle();
  const { damageTaken } = useDamage({ prevTurn, player });
  const [deckIsOpen, setDeckIsOpen] = useState(true);
  return (
    <>
      <section
        className={` flex w-full ${
          isUser ? "flex-col-reverse" : "flex-col"
        } items-center justify-center gap-2 px-2 xs:gap-3 md:flex-row`}
      >
        {isUser && (
          <aside
            className={`flex h-max flex-col gap-2 rounded-lg bg-[#121212c8] p-3 text-left md:max-w-[190px] `}
          >
            <span className="hidden text-center text-sm md:block">
              {player.player}
            </span>
            <section className={`flex gap-3 md:flex-col`}>
              <div className={`relative flex items-center gap-2`}>
                <span className={` text-red-600 `}>
                  <FaHeart />
                </span>
                <AtributeBar
                  maxAmount={player?.details?.pet?.totalHp || 0}
                  currentAmount={player?.details?.pet?.currentHp || 0}
                  color="#2ecc71"
                  minimal
                />
              </div>
              <div className={`flex items-center gap-2`}>
                <span className={`text-[#0984e3] `}>
                  <FaDroplet />
                </span>
                <AtributeBar
                  maxAmount={player?.details?.pet?.manaMax || 0}
                  currentAmount={player?.details?.pet?.currentMana || 0}
                  color="#0984e3"
                  minimal
                />
              </div>
            </section>
            <section
              className={`flex w-full flex-col items-center justify-between gap-1`}
            >
              <ActionsBattleButtonsMobile
                activeTurn={activeTurn}
                disabledButtons={disabledButtons}
                setDisabledButtons={setDisabledButtons}
                handleBattleAction={buttonAction}
                user={player}
              />
            </section>
            <span className="text-center text-sm md:hidden">
              {player.player}
            </span>
          </aside>
        )}

        <section
          style={{ width: `100%` }}
          className="relative flex items-center justify-center transition-all lg:hidden"
        >
          <div
            style={{ width: `${deckIsOpen === true ? "572px" : "190px"}` }}
            className=" transition-all lg:hidden "
          >
            {player && (
              <PlayerDetailsMobile
                user={player}
                opponent={opponent}
                deckIsOpen={deckIsOpen}
                setDeckIsOpen={setDeckIsOpen}
                damageTaken={damageTaken}
                haveCannon={Boolean(
                  player?.details?.cannon?.strength ||
                    player?.details?.cannon?.cannon_damage,
                )}
                isUser={isUser}
              />
            )}
          </div>
        </section>
      </section>
    </>
  );
};
