import { TbVolume, TbVolumeOff } from "react-icons/tb";

import useSound from "../../../../hooks/useSound";
export const SoundButton = () => {
  const { isDisabledSound, toggleSoundPreference } = useSound();
  return (
    <button
      className="gamebtn !h-[40px] !w-[40px] !px-2 !py-1 text-xl "
      onClick={toggleSoundPreference}
    >
      {isDisabledSound ? <TbVolume /> : <TbVolumeOff />}
    </button>
  );
};
