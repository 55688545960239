import { useGame } from "../../../../hooks/useGame";
import { useSocket } from "../../../../hooks/useSocket";
import { BalanceBoard } from "../../../BalanceBoard";
import { BattleStage } from "../BattleStage";
import { QueueStage } from "../QueueStage";
import { SelectionStage } from "../SelectionStage";
interface Props {
  handleOnClickMenu: (param: string) => void;
}

export const GameMenu = ({ handleOnClickMenu }: Props) => {
  const { handleEnterQueue, handleQuitQueue } = useSocket();
  const { stage, gameLoading } = useGame();

  const setSelectionTypeSelection = card => {
    handleOnClickMenu(`selection-${card}`);
  };

  const handleMatchButton = (item: "find" | "cancel") => {
    if (item === "find") {
      return handleEnterQueue();
    }

    if (item === "cancel") {
      return handleQuitQueue();
    }
  };

  if (stage !== "queue" && stage !== "selection")
    return (
      <div className="relative flex min-h-screen w-screen items-center justify-center">
        <BattleStage />
      </div>
    );

  const isEndGame = stage.includes("reward");

  return (
    <div className="mb-12 flex flex-1 flex-col-reverse items-center justify-center gap-4 md:mb-0 md:flex-row">
      <div className="min-h-[300px] w-[350px] min-w-[200px] rounded-2xl bg-[#000000a7] px-4 py-4 shadow-2xl md:w-auto md:px-16">
        {!isEndGame && <h3 className="text-center">Prepare for Battle</h3>}
        <div className="flex h-full w-full flex-col items-center justify-center">
          {stage === "selection" && (
            <SelectionStage
              setSelectionTypeSelection={setSelectionTypeSelection}
              handleMatchButton={handleMatchButton}
            />
          )}
          {stage === "queue" && (
            <QueueStage handleMatchButton={handleMatchButton} />
          )}
          {/* {isEndGame && <RewardStage />} */}
        </div>
      </div>
      <BalanceBoard
        className={
          "bottom-0 right-12 top-0 my-auto flex justify-around gap-2 md:w-auto md:flex-col lg:absolute lg:h-[520px] lg:gap-0"
        }
      />
    </div>
  );
};
