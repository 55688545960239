import { useEffect, useState } from "react";

import { ITurn, PlayerOne } from "../../../@types/socket";
import { useDamage } from "../../../hooks/useDamage";
import { useGame } from "../../../hooks/useGame";
import { useGameLogInfos } from "../../../hooks/useGameLogInfos";
import { joinedLogMessages } from "../../../utils/constants";

export const GameLogs = ({
  prevTurn,
  user,
  roundData,
  roundNumber,
}: {
  prevTurn: ITurn | undefined;
  user: PlayerOne;
  roundData: any;
  roundNumber: number;
}) => {
  const { stage, setStage } = useGame();

  const [showRoundWinner, setShowRoundWinner] = useState(false);
  const [currentRound, setCurrentRound] = useState<number>(roundNumber);
  const [endGame, setEndgame] = useState(
    stage.includes("reward") || stage === "reward-draw",
  );

  useEffect(() => {
    const checkHealths = () => {
      const roundsWithWinner = roundData?.data?.game?.rounds
        ?.filter(
          item =>
            item.winnerId !== null || (item.isDraw !== null && item.isDraw),
        )
        .sort((a, b) => a.round - b.round);

      if (roundsWithWinner >= 2) {
        return true;
      }

      if (roundData?.data?.game?.rounds > 2) {
        const player1 = roundData?.players?.playerOne;
        const player2 = roundData?.players?.playerTwo;
        if (
          player1?.details?.pet?.currentHp < 1 ||
          player2?.details?.pet?.currentHp < 1
        ) {
          return true;
        }
        return false;
      }

      return false;
    };

    setEndgame(
      stage.includes("reward") || stage === "reward-draw" || checkHealths(),
    );
  }, [stage, roundData]);

  const roundsWithWinner = roundData?.data.game?.rounds?.filter(
    item => item.winnerId !== null || (item.isDraw !== null && item.isDraw),
  );

  const isOpponentAFK =
    roundData.data.game_won_abandon && roundData.data.game_won_abandon;

  const isUserAFK =
    roundData.data.game_abandoned && roundData.data.game_abandoned;

  const isBothAFK = roundData.data.game_away && roundData.data.game_away;

  const findRound =
    !roundData.data.game_won_abandon &&
    roundsWithWinner?.find(item => item.round === currentRound);

  const isRankedGame = roundData.data.game?.isRanked;
  const isFirstTurn = !prevTurn && roundNumber === 1;

  useEffect(() => {
    if (findRound) {
      setShowRoundWinner(true);
    }

    setTimeout(() => {
      setShowRoundWinner(false);
      setCurrentRound(roundNumber);
    }, 3000);
  }, [findRound, roundNumber]);

  const { damageDealt, damagePersisted } = useDamage({
    prevTurn,
    player: user,
  });

  const { ownerMessage, opponentMessage } = useGameLogInfos({
    roundData,
    prevTurn,
    user,
    damageDealt,
    damagePersisted,
  });

  // useEffect(() => {
  //   const isWalletOwner = prevTurn?.actions.find(
  //     action => user.deck.playerId === action.playerId,
  //   )?.details;

  //   const isOpponent = prevTurn?.actions.find(
  //     action => user.deck.playerId !== action.playerId,
  //   )?.details;

  //   console.log({
  //     isWalletOwner,
  //     isOpponent,
  //     ownerMessage,
  //     opponentMessage,
  //     damageDealt,
  //     damagePersisted,
  //   });
  // }, [
  //   damageDealt,
  //   damagePersisted,
  //   opponentMessage,
  //   ownerMessage,
  //   prevTurn?.actions,
  //   user.deck.playerId,
  // ]);

  const verifyEndturnAway = !prevTurn || !user || !prevTurn?.actions?.length;

  if (!showRoundWinner && !prevTurn && !endGame && !isFirstTurn) return null;

  const notOkayMessage = joinedLogMessages.nok.includes(ownerMessage);
  const okayMessage = joinedLogMessages.ok.includes(opponentMessage);

  const classMessage = notOkayMessage
    ? "text-red-400"
    : okayMessage
    ? "text-green-400"
    : "text-white";

  return (
    <section className={`flex min-h-[90px] gap-1 md:gap-2`}>
      {/* {endGame && (
        <aside
          className={` ml-2 flex flex-col items-center justify-center rounded-xl bg-black/80 p-2 xs:gap-2 xs:p-3 sm:p-4`}
        >
          <span className={`text-xs sm:text-sm md:text-base`}>Match over!</span>

          <button
            onClick={() => {
              setStage("selection");
            }}
            className="gamebtn text-xs transition-all hover:scale-110 sm:text-sm md:text-base"
          >
            Leave
          </button>
        </aside>
      )} */}
      <section
        className={`mr-2 flex flex-col items-center justify-center gap-0.5 rounded-xl bg-black/80 p-2 xs:gap-1 xs:p-3 sm:p-4`}
      >
        {opponentMessage === ownerMessage && opponentMessage !== "" ? (
          <>
            {prevTurn && user && prevTurn.actions?.length ? (
              <span
                className={`text-center text-xs font-medium ${classMessage} md:text-base`}
              >
                {opponentMessage}
              </span>
            ) : null}

            {showRoundWinner && renderWinnerBattle(findRound, user, setStage)}

            {endGame &&
              renderWinnerMatch({
                stage,
                isOpponentAFK,
                isUserAFK,
                isBothAFK,
                setStage,
              })}

            {verifyEndturnAway && !endGame && !isFirstTurn ? (
              <span className={`text-xs md:text-sm`}>
                The turn ended with both players away.
              </span>
            ) : null}

            {isFirstTurn && renderRankedOrNotFirstTurn({ isRankedGame })}
          </>
        ) : (
          <>
            {prevTurn && user && prevTurn.actions?.length ? (
              <span
                className={`text-center text-xs font-medium text-red-400 md:text-base`}
              >
                {opponentMessage}
              </span>
            ) : null}

            {showRoundWinner && renderWinnerBattle(findRound, user, setStage)}

            {endGame &&
              renderWinnerMatch({
                stage,
                isOpponentAFK,
                isUserAFK,
                isBothAFK,
                setStage,
              })}

            {verifyEndturnAway && !endGame && !isFirstTurn ? (
              <span className={`text-xs md:text-sm`}>
                The turn ended with both players away.
              </span>
            ) : null}

            {prevTurn && user && prevTurn?.actions?.length ? (
              <span
                className={`text-center text-xs font-medium text-green-400 md:text-base`}
              >
                {ownerMessage}
              </span>
            ) : null}

            {isFirstTurn && renderRankedOrNotFirstTurn({ isRankedGame })}
          </>
        )}
      </section>
    </section>
  );
};

const renderWinnerBattle = (find, user, setStage) => {
  if (find) {
    return (
      <div>
        <span className={`text-xs md:text-sm`}>
          {find?.winnerId === user?.deck?.playerId
            ? `you won round  ${find?.round}`
            : find?.isDraw
            ? "Game Draw"
            : ` your opponent won round ${find?.round}`}
        </span>
      </div>
    );
  }
};

const renderRankedOrNotFirstTurn = ({ isRankedGame }) => {
  const rankedOrNotMessage = isRankedGame
    ? "This match counts for ranking purposes"
    : "This match doesn't count for ranking purposes.";

  return <span className={`text-xs md:text-sm`}>{rankedOrNotMessage}</span>;
};

const renderWinnerMatch = ({
  stage,
  isUserAFK = false,
  isOpponentAFK = false,
  isBothAFK = false,
  setStage,
}) => {
  const battleResult = stage?.split("-")[1];

  const isWin = battleResult === "win";
  const isDraw = battleResult === "draw";

  let message = <></>;

  if (isOpponentAFK) {
    message = (
      <span className={`text-center text-xs md:text-sm`}>
        You won the match because your opponent was away.
      </span>
    );
  } else if (isUserAFK) {
    message = (
      <span className={`text-center text-xs md:text-sm`}>
        You lost the match because you didn&apos;t take any action.
      </span>
    );
  } else if (isBothAFK) {
    message = (
      <span className={`text-center text-xs md:text-sm`}>
        The match ended in a draw because both players were away.
      </span>
    );
  } else
    message = (
      <>
        {isWin ? (
          <span className=" text-center text-xs md:text-sm">
            <span>You have won the match!</span>
          </span>
        ) : isDraw ? (
          <span className="flex flex-col items-center justify-center text-center text-xs md:text-sm">
            The battle ended in a draw.
            {/* <br /> Both players aren&apos;t getting any rewards or losses. */}
          </span>
        ) : (
          <span className={`text-center text-xs md:text-sm`}>
            You have lost the match!
          </span>
        )}
      </>
    );

  return (
    <div className="flex flex-col items-center justify-center gap-2">
      {message}
      <button
        onClick={() => {
          setStage("selection");
        }}
        className="gamebtn w-[50%] text-xs transition-all  hover:scale-110 sm:text-sm md:text-base"
      >
        Leave
      </button>
    </div>
  );
};
