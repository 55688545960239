interface Props {
  maxAmount: number;
  currentAmount: number;
  color: string;
  minimal?: boolean;
}

export const AtributeBar = ({
  maxAmount,
  currentAmount,
  color,
  minimal = false,
}: Props) => {
  // check if currentAmount is greater than 0
  const currentAmountCapped = currentAmount > 0 ? currentAmount : 0;
  const percentage = (currentAmountCapped / maxAmount) * 100;
  return (
    <div
      className={
        "relative flex items-center justify-center" +
        (minimal ? " h-7 w-14 flex-col" : "")
      }
    >
      <p className={"absolute top-0.5 text-center text-sm !text-white"}>
        {currentAmount} / {maxAmount}
      </p>
      {!minimal && (
        <div className="h-7 w-28 overflow-hidden rounded-full border-2 border-white/70 bg-white/25">
          <div
            style={{ backgroundColor: `${color}`, width: `${percentage}%` }}
            className={`h-6 rounded-full`}
          />
        </div>
      )}
    </div>
  );
};
