import { motion } from "framer-motion";
import Image from "next/image";
import { useState, useEffect } from "react";

import { useFetch } from "../../../hooks/useFetch";
import { IFPS_URL } from "../../../utils/constants";
import Link from "next/link";

interface IReward {
  id: number;
  wallet: string;
  task_name: string;
  template_id: number;
  is_nft: number;
  value: string;
  claimed_at: number;
  token_symbol: string;
}

type IRewards = IReward[];

export const RewardsModalContent = ({
  item,
  close,
}: {
  item: IRewards;
  close: () => void;
}) => {
  const { zombieTemplates } = useFetch();

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: [0, 0.5, 1, 1.2, 1] }}
      className="relative flex flex-col items-center justify-center gap-4"
    >
      <div className="flex flex-col items-center justify-center">
        <h3 className="gold-text animate-shimmer bg-[length:200%_100%]">
          Congratulations!
        </h3>
      </div>
      <div>
        <p>You Earned:</p>
      </div>

      <div className="relative flex items-center justify-center">
        {[...Array(24)].map((_, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 1, y: 0 }}
            animate={{
              opacity: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
              y: [
                0,
                -350 - Math.ceil(Math.random() * 150 * (index % 6)),
                500 - Math.ceil(Math.random() * 150 * (index % 6)),
                550 - Math.ceil(Math.random() * 150 * (index % 6)),
                600 - Math.ceil(Math.random() * 150 * (index % 6)),
                700 - Math.ceil(Math.random() * 150 * (index % 6)),
                800 - Math.ceil(Math.random() * 150 * (index % 6)),
                1500 - Math.ceil(Math.random() * 150 * (index % 6)),
                3000 - Math.ceil(Math.random() * 150 * (index % 6)),
              ],
              x:
                index < 12
                  ? [
                      -100 - (index % 12) * 15,
                      -150 -
                        (index % 12) * 15 * Math.pow(2, [4, 5, 6][index % 3]),
                    ]
                  : [
                      100 + (index % 12) * 15,
                      150 +
                        (index % 12) * 15 * Math.pow(2, [4, 5, 6][index % 3]),
                    ],
            }}
            transition={{ duration: 2, ease: "easeInOut" }}
            className={`absolute h-2 w-2 rounded-full ${
              ["bg-red-500", "bg-blue-500", "bg-green-500", "bg-yellow-500"][
                index % 4
              ]
            }`}
          />
        ))}
      </div>

      <div className="flex flex-col items-center justify-center">
        {item && Array.isArray(item) && !item?.[0] ? (
          <>Couldn&apos;t fetch rewards</>
        ) : (
          item?.map((reward, index) => {
            const templateData = zombieTemplates?.find(
              zt => +zt.template_id === +reward.template_id,
            );
            return (
              <div key={index} className="cursor-pointer">
                <a
                  rel="noreferrer noopener"
                  href={`https://wax.atomichub.io/explorer/template/wax-mainnet/zombiecoinzz/${reward.template_id}`}
                  target="_blank"
                >
                  <div className="relative flex items-center justify-center rounded-lg border-2 border-black bg-zombie_brown-900">
                    <div className="radial-blue z-1 absolute h-[70%] w-[70%] rounded-full bg-[rgb(122,182,239)]" />
                    {reward.is_nft ? (
                      <div className="z-2 tooltip relative min-h-[54px] min-w-[54px]">
                        <div className="button !-left-16">
                          {templateData?.immutable_data?.name || ""}
                        </div>
                        <Image
                          width={54}
                          height={54}
                          src={`${IFPS_URL}${templateData?.immutable_data?.img}`}
                          alt="Reward Icon"
                          className="z-2 relative "
                        />
                      </div>
                    ) : (
                      <div className="z-2 relative flex h-[54px] w-[54px] items-center justify-center text-center font-bold text-black">
                        <p className="text-black">{reward?.token_symbol}</p>
                      </div>
                    )}

                    <div className="z-3 stroke-zombie_black gold-text absolute -bottom-3 -left-2 whitespace-nowrap stroke-2 font-bold">
                      {`${Number(reward.value.split(" ")[0])}x`}
                    </div>
                  </div>
                </a>
              </div>
            );
          })
        )}
      </div>
      <div className="rounded-[15px] border-b-4 border-b-black">
        <button
          className="gamebtn !bg-green-500 text-center disabled:!grayscale"
          onClick={close}
        >
          Close
        </button>
      </div>
    </motion.div>
  );
};
