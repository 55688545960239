import { HotkeyItem, useHotkeys } from "@mantine/hooks";
import { useRouter } from "next/router";
import { useEffect } from "react";
import toast from "react-hot-toast";
import {
  GiBrokenShield,
  GiCannon,
  GiShield,
  GiSwordWound,
} from "react-icons/gi";

import { GameActions, useSound } from "../../../hooks/useSound";

const icons = [
  { label: GameActions.attack, target: "A", icon: <GiSwordWound /> },
  { label: GameActions.defend, target: "S", icon: <GiShield /> },
  { label: GameActions.break, target: "D", icon: <GiBrokenShield /> },
  { label: GameActions.cannon, target: "F", icon: <GiCannon /> },
];
export const ActionsBattleButtons = ({
  handleBattleAction,
  user,
  disabledButtons,
  setDisabledButtons,
  activeTurn,
}) => {
  const router = useRouter();
  const { gameSound } = useSound();

  function handleAttackViaHotkey(action: string) {
    const sentryShadowRoot = document.getElementById("sentry-feedback");
    const sentryDialog = sentryShadowRoot?.shadowRoot?.querySelector(
      "dialog.dialog",
    ) as HTMLDialogElement;

    if (sentryDialog?.open) {
      return;
    }

    if (disabledButtons) {
      toast.error("You already chose your action.");
      return;
    }
    if (action === GameActions.cannon && !canUseCannon()) {
      toast.error("You can't use the cannon now.");
      return;
    }

    if (action === GameActions.defend && !canDefend()) {
      toast.error("You can't defend now.");
      return;
    }

    router.push({
      query: {
        ...router.query,
        action,
      },
    });

    handleBattleAction(action);
    setDisabledButtons(true);
  }

  const hotKeys = icons.map(
    icon =>
      [
        icon.target,
        () => handleAttackViaHotkey(icon.label),
        { preventDefault: false },
      ] as HotkeyItem,
  );

  useHotkeys(hotKeys);

  const haveCannon = () => Boolean(user?.details?.cannon?.cannonDamage);
  const canUseCannon = () =>
    haveCannon() &&
    !disabledButtons &&
    +user?.details?.pet?.currentMana >= +user?.details?.cannon?.mana_cost;

  const defenseCooldown = Boolean(user?.details?.pet?.defenseCooldown);
  const canDefend = () => !disabledButtons && !defenseCooldown;
  const petName = user?.deck.cards.find(card => card.type === "pet").attributes
    .name;
  const queryActionFilter = String(router.query.action);

  useEffect(() => {
    if (activeTurn) {
      gameSound({ typeAction: queryActionFilter, petName });
    }
  }, [activeTurn]);

  // useEffect(() => {

  //   setSentryDialog(sentryDialog?.open || false);
  // })

  return (
    <>
      <div className="hidden w-full items-center justify-center gap-2 md:flex">
        {icons.map((battleAction, _) => {
          return (
            <button
              key={_}
              className="gamebtn !flex items-center  gap-1 !bg-[#111845] !text-sm md:!text-xl"
              onClick={() => {
                router.push({
                  query: {
                    ...router.query,
                    action: battleAction.label,
                  },
                });
                handleBattleAction(battleAction.label);
                setDisabledButtons(true);
              }}
              disabled={
                battleAction.label === "Cannon"
                  ? !canUseCannon()
                  : battleAction.label === "Defend"
                  ? !canDefend()
                  : disabledButtons
              }
            >
              {battleAction.label} {battleAction.icon}
            </button>
          );
        })}
      </div>
    </>
  );
};

export const ActionsBattleButtonsMobile = ({
  handleBattleAction,
  user,
  disabledButtons,
  setDisabledButtons,
  activeTurn,
}) => {
  const router = useRouter();
  const { gameSound } = useSound();
  const haveCannon = () => Boolean(user?.details?.cannon?.cannonDamage);
  const canUseCannon = () =>
    haveCannon() &&
    !disabledButtons &&
    +user?.details?.pet?.currentMana >= +user?.details?.cannon?.mana_cost;

  const defenseCooldown = Boolean(user?.details?.pet?.defenseCooldown);
  const canDefend = () => !disabledButtons && !defenseCooldown;
  const petName = user?.deck.cards.find(card => card.type === "pet").attributes
    .name;
  const queryActionFilter = String(router.query.action);

  useEffect(() => {
    if (activeTurn) {
      gameSound({ typeAction: queryActionFilter, petName });
      router.push({
        query: {
          ...router.query,
          action: "",
        },
      });
    }
  }, [activeTurn]);

  return (
    <div className="flex w-max flex-wrap items-center justify-center gap-3 md:hidden">
      {icons.map((battleAction, _) => {
        return (
          <button
            key={_}
            className={`gamebtn !p-2 text-[22px] transition-all active:scale-90 ${
              disabledButtons && "opacity-50"
            } `}
            onClick={() => {
              router.push({
                query: {
                  ...router.query,
                  action: battleAction.label,
                },
              });
              handleBattleAction(battleAction.label);
              setDisabledButtons(true);
            }}
            disabled={
              battleAction.label === "Cannon"
                ? !canUseCannon()
                : battleAction.label === "Defend"
                ? !canDefend()
                : disabledButtons
            }
          >
            {battleAction.icon}
          </button>
        );
      })}
    </div>
  );
};
