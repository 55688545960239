import { useGame } from "../../../../hooks/useGame";
import { SelectionItem } from "../../Items/SelectionItem";
import { PlayersQueueCounter } from "../../PlayersQueueCounter";

export const QueueStage = ({ handleMatchButton }) => {
  const { selectedCards } = useGame();

  return (
    <div className="relative flex min-w-[300px] flex-col items-center justify-center gap-6">
      <p className="text-center">
        The max wait is 1 minute. If no other player is found, a bot will
        challenge you.
      </p>
      <PlayersQueueCounter />
      <div className="flex gap-4">
        {selectedCards &&
          Object.entries(selectedCards).map((card: any, _: number) => {
            return (
              <div
                key={_}
                className="flex flex-col items-center justify-center gap-2 md:flex-row"
              >
                <div className="flex min-h-[160px] min-w-[80px] cursor-pointer flex-col items-center justify-center rounded-md bg-[#00000051] px-0 shadow-2xl transition-all hover:bg-[#00000079] md:h-[300px] md:w-[200px] md:px-4">
                  {card[1] ? (
                    <SelectionItem
                      item={card[1]}
                      type={() => null}
                      handleSelectCard={() => null}
                    />
                  ) : (
                    <>
                      <div className="flex flex-1 items-center justify-center">
                        <div className="m-auto flex h-[20px] w-[20px] items-center justify-center rounded-full bg-black text-center text-[18px]">
                          &#x1f512;
                        </div>
                      </div>
                      <div>
                        {
                          {
                            pet: "Pet Slot",
                            cannon: "Cannon Slot",
                            boost: "Amulet Slot",
                          }[card[0]]
                        }
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          })}
      </div>
      <a className="inventory-a">
        <div className="gamebtn transition-all hover:scale-110">
          <div className="content" onClick={() => handleMatchButton("cancel")}>
            Cancel
          </div>
        </div>
      </a>
    </div>
  );
};
