import Image from "next/image";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";

import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { useFetch } from "../../../hooks/useFetch";
import useWax, { IMutateOptions } from "../../../hooks/useWax";
import { SWRCacheKeyGetters, atomicFetcher } from "../../../services/swr";
import { cleanAndCapitalize, findAttrCaps } from "../../../utils";
import {
  atributeUpgradeStep,
  IFPS_URL,
  trainPotionTemplateId,
} from "../../../utils/constants";

export const TrainModalContent = ({ item, close }) => {
  const { currentUser } = useCurrentUser();
  const { userInventory, isLoadingUserInventory, levelCap, zombieTemplates } =
    useFetch();
  const { contractLevelUpAtribute } = useWax();
  const [attribute, setAttribute] = useState<string>("hp");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const invItem = userInventory.find(e => +e.assetId === +item.assetId);

  const itemLevelCap: any = findAttrCaps(invItem, levelCap);

  const potions = userInventory?.filter(
    nft => +nft?.templateId === +trainPotionTemplateId,
  );

  const potionsTemplates = zombieTemplates?.filter(
    template => +template?.template_id === +trainPotionTemplateId,
  );

  const mutateOptions: IMutateOptions[] = [
    {
      key: SWRCacheKeyGetters.getUserInventory(currentUser),
      currentData: userInventory,
      fetcher: atomicFetcher,
    },
  ];

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 2000);
  }, [isLoading]);

  if (isLoadingUserInventory || isLoading || !invItem)
    return (
      <div className="relative flex items-center justify-center">
        <div className="loading-circle"></div>
      </div>
    );

  return (
    <div className="relative flex flex-col items-center justify-center gap-4">
      <div className="flex flex-col items-center justify-center">
        <h2 className={`text-2xl`}>Increase Pet Attributes</h2>
        <small className={`text-base`}>
          Select the attribute you want to increase. It will cost you 1{" "}
          {potionsTemplates?.[0]?.immutable_data?.name}.
        </small>
      </div>
      <div>
        <div className="flex items-center justify-center">
          <Image
            src={`${invItem?.img}`}
            width={128}
            height={120}
            alt={`${invItem?.data?.name} NFT Image`}
          />
          <span>&#43;</span>
          <Image
            src={`${IFPS_URL}${potionsTemplates?.[0]?.immutable_data?.img}`}
            width={64}
            height={64}
            alt={`${potionsTemplates?.[0]?.immutable_data?.name} NFT iamge`}
          />
        </div>
        <div className="text-center">
          {invItem?.data?.name} ({invItem?.assetId})
        </div>
      </div>
      <div>
        <div className="flex flex-row items-center justify-center gap-2 overflow-hidden rounded-xl">
          <select
            className={`h-[50px] w-[200px] rounded-lg bg-[#3B3B3B] p-2 text-center outline-none`}
            value={attribute}
            onChange={evt => setAttribute(evt.target.value)}
          >
            {Object.entries(invItem?.data).map(([attrKey, attrValue], _) => {
              if (
                [
                  "hp",
                  "strength",
                  "obedience",
                  "dexterity",
                  "intelligence",
                ].includes(attrKey)
              )
                return (
                  <option
                    className="outline-none"
                    key={_}
                    value={attrKey}
                    disabled={
                      +(attrValue as string) ===
                      +itemLevelCap?.[
                        attrKey === "hp" ? "max_hp" : "max_attr_value"
                      ]
                    }
                  >
                    {cleanAndCapitalize(attrKey)} ({attrValue as string}/
                    {
                      itemLevelCap?.[
                        attrKey === "hp" ? "max_hp" : "max_attr_value"
                      ]
                    }
                    )
                  </option>
                );
              return null;
            })}
          </select>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center">
        <span>
          This action will add <span>+{atributeUpgradeStep[attribute]}</span> to{" "}
          {cleanAndCapitalize(attribute)}
        </span>
      </div>
      <div className="rounded-[15px] border-b-4 border-b-black">
        <button
          className="gamebtn !border-green-500/50 !bg-green-500/50 text-center disabled:!grayscale"
          disabled={potions?.length <= 0}
          onClick={() => {
            try {
              contractLevelUpAtribute({
                attribute,
                card_asset_id: invItem?.assetId,
                fruit_asset_ids: potions?.[0]?.assetId,
                mutateOptions,
                onSuccess: () => setIsLoading(true),
              });

              // close();
            } catch (err) {
              toast(err as string);
            }
          }}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};
