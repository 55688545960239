import { useContext } from "react";

import { SoundContext } from "../context/SoundContext";

export const GameActions = {
  start_match: "Match_start",
  attack: "Attack",
  cannon: "Cannon",
  break: "Break",
  defend: "Defend",
};

export const useSound = () => {
  const context = useContext(SoundContext);

  return context;
};

export default useSound;
