import Image from "next/image";
import { useState } from "react";
import toast from "react-hot-toast";

import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { useFetch } from "../../../hooks/useFetch";
import useWax, { IMutateOptions } from "../../../hooks/useWax";
import { SWRCacheKeyGetters, atomicFetcher } from "../../../services/swr";
import { findAttrCaps, findNextLevelAttrCaps } from "../../../utils";
import { IFPS_URL, levelUpPotionTemplateId } from "../../../utils/constants";
import { levelUpPotionCost } from "../../../utils/errorHandlers";

export const LevelUpModalContent = ({ item, close }) => {
  const { currentUser } = useCurrentUser();
  const { userInventory } = useFetch();
  const { contractLevelUpCharacter } = useWax();
  const { levelCap } = useFetch();

  const itemLevelCap: any = findAttrCaps(item, levelCap);
  const itemNextLevelCap: any = findNextLevelAttrCaps(item, levelCap);

  const potions = userInventory.filter(
    nft => +nft?.templateId === +levelUpPotionTemplateId,
  );

  const mutateOptions: IMutateOptions[] = [
    {
      key: SWRCacheKeyGetters.getUserInventory(currentUser),
      currentData: userInventory,
      fetcher: atomicFetcher,
    },
  ];

  return (
    <div className="relative flex flex-col items-center justify-center gap-4">
      <div className="flex flex-col items-center justify-center">
        <h3 className="">Level Up Pet</h3>
        <small className="text-center">
          Leveling up your pet will increase their maximum attributes
          <br /> It will cost you{" "}
          {`${levelUpPotionCost(+item?.data?.level)} ${
            potions?.[0]?.data?.name
          }`}
          .
        </small>
      </div>
      <div>
        <div className="flex items-center justify-center">
          <Image
            src={`${item?.img}`}
            width={128}
            height={120}
            alt={`${item?.data?.name} NFT Image`}
          />
          <span>&#43;</span>
          <Image
            src={`${potions?.[0]?.img}`}
            width={64}
            height={64}
            alt={`${potions?.[0]?.data?.name} NFT iamge`}
          />
        </div>
        <div className="text-center">
          {item?.data?.name} ({item?.assetId})
        </div>
      </div>

      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-col text-xl">
          <span className="flex gap-2">
            Level {itemLevelCap?.level}
            {" -> "}
            Level {itemNextLevelCap?.level}
          </span>
        </div>
        <div className="flex flex-wrap gap-4">
          {["Max Hp", "Max Attributes"].map((attrKey, _) => {
            return (
              <div
                className="flex flex-col items-center justify-center text-center"
                key={_}
              >
                <span
                  className={attrKey === "Max Attributes" ? "gold-text" : ""}
                >
                  {attrKey}
                </span>
                <span className="flex gap-2">
                  {
                    itemLevelCap?.[
                      attrKey === "Max Hp" ? "max_hp" : "max_attr_value"
                    ]
                  }
                  {"->"}
                  {
                    itemNextLevelCap?.[
                      attrKey === "Max Hp" ? "max_hp" : "max_attr_value"
                    ]
                  }
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="rounded-[15px] border-b-4 border-b-black">
        <button
          className="gamebtn !bg-green-500 text-center disabled:!grayscale"
          disabled={potions.length <= 0}
          onClick={() => {
            try {
              const cost = levelUpPotionCost(+item?.data?.level);
              const fruit_asset_ids = potions
                .slice(0, levelUpPotionCost(cost))
                .map(nft => nft.assetId);
              contractLevelUpCharacter(
                {
                  card_asset_id: +item.assetId,
                  fruit_asset_ids,
                },
                mutateOptions,
              );

              close();
            } catch (err) {
              toast(err as string);
            }
          }}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};
