import { ITurn, PlayerOne } from "../@types/socket";
import { handleBattleMessage } from "../utils/battleMessages";
import { useDamage } from "./useDamage";

export const useGameLogInfos = ({
  roundData,
  prevTurn,
  user,
  damageDealt,
  damagePersisted,
}: {
  roundData: any;
  prevTurn: ITurn | undefined;
  user: PlayerOne;
  damageDealt: number;
  damagePersisted: number;
}) => {
  const isOpponentAFK =
    roundData?.data.game_won_abandon && roundData?.data.game_won_abandon;

  const isUserAFK =
    roundData?.data.game_abandoned && roundData?.data.game_abandoned;

  const isBothAFK = roundData?.data.game_away && roundData?.data.game_away;

  const isWalletOwner = prevTurn?.actions
    ?.find(action => user.deck.playerId === action.playerId)
    ?.details?.split(" ");

  const isOpponent = prevTurn?.actions
    ?.find(action => user.deck.playerId !== action.playerId)
    ?.details?.split(" ");

  const opponentMessage = !isOpponent
    ? ""
    : `Your opponent ${isOpponent?.slice(2, isOpponent.length).join(" ")}`;

  const ownerMessage = !isWalletOwner
    ? ""
    : `You ${isWalletOwner?.slice(2, isWalletOwner.length).join(" ")}`;

  const ownerIsAttacking =
    ownerMessage.includes("made") && ownerMessage.includes("attack");
  const ownerIsCA =
    ownerMessage.includes("counter attack") && ownerMessage.includes("attack");
  const ownerIsCannoning =
    ownerMessage.includes("cannon attack") && ownerMessage.includes("made");
  const ownerIsMissed =
    ownerMessage.includes("and missed") ||
    ownerMessage.includes("has missed") ||
    ownerMessage.includes("and took");
  const ownerIsDefending =
    ownerMessage.includes("made a defend") ||
    ownerMessage.includes("made a defense") ||
    ownerMessage.includes("used a defense");
  const ownerIsBreakingBlock = ownerMessage.includes("made a break");

  const opponentIsAttacking =
    opponentMessage.includes("made") && opponentMessage.includes("attack");
  const opponentIsCA =
    opponentMessage.includes("counter attack") &&
    ownerMessage.includes("attack");
  const opponentIsCannoning =
    opponentMessage.includes("cannon attack") &&
    opponentMessage.includes("made");
  const opponentIsMissed =
    opponentMessage.includes("and missed") ||
    opponentMessage.includes("has missed") ||
    opponentMessage.includes("and took");
  const opponentIsDefending =
    opponentMessage.includes("made a defend") ||
    opponentMessage.includes("made a defense") ||
    opponentMessage.includes("used a defense");
  const opponentIsBreakingBlock = opponentMessage.includes("made a break");

  // console.log({ ownerMessage, opponentMessage });

  const { ownerMessageFinal, opponentMessageFinal } = handleBattleMessage({
    owner: {
      ownerIsAttacking,
      ownerIsCA,
      ownerIsBreakingBlock,
      ownerIsCannoning,
      ownerIsDefending,
      ownerIsMissed,
    },
    opponent: {
      opponentIsAttacking,
      opponentIsCA,
      opponentIsBreakingBlock,
      opponentIsCannoning,
      opponentIsDefending,
      opponentIsMissed,
    },
    damageDealt,
    damagePersisted,
  });

  // console.log({ opponentMessage, ownerMessage });
  // console.log({ opponentMessageFinal, ownerMessageFinal });

  return {
    isOpponentAFK,
    isUserAFK,
    isBothAFK,
    opponentMessage: opponentMessageFinal,
    ownerMessage: ownerMessageFinal,
  };
};
