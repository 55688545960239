import Image from "next/image";

import { useFetch } from "../../hooks/useFetch";
import {
  LevelUpPotionName,
  AttributePotionName,
  IFPS_URL,
  levelUpPotionTemplateId,
  trainPotionTemplateId,
} from "../../utils/constants";

export const BalanceBoard = ({ className = "" }) => {
  const {
    zombieTokenBalance,
    zombieTokenBalanceIsLoading,
    zombieTemplates,
    userInventory,
  } = useFetch();

  const levelupPotion =
    zombieTemplates?.find(
      nft => nft.template_id === +levelUpPotionTemplateId,
    ) || [];
  const attributePotion =
    zombieTemplates?.find(nft => nft.template_id === +trainPotionTemplateId) ||
    [];

  const userLevelUpPotions =
    userInventory?.filter(
      nft => nft?.templateId === +levelUpPotionTemplateId,
    ) || [];
  const userAttributePotion =
    userInventory?.filter(nft => nft?.templateId === +trainPotionTemplateId) ||
    [];

  const mysticEssenceElixir = `${IFPS_URL}QmSqJEm6TQSityEr12reRxx4Xgvsn67Q3aKxAdz3abWoGa`;
  const ascencionElixir = `${IFPS_URL}QmZFAh1XFh5MxsMGAWsi1YzSThNyh9o6oK2tARMvvm4nPx`;

  const renderAssets = () => {
    return [
      [attributePotion, userAttributePotion?.length],
      [levelupPotion, userLevelUpPotions?.length],
    ].map(([template, amount], index) =>
      !template ? (
        <div
          key={index}
          className="loading-circle !h-[75px] !w-[75px] after:hidden"
        ></div>
      ) : (
        <div key={index}>
          <div className=" items-center justify-center rounded-full bg-gray-900 p-4">
            <div className="relative h-[48px] w-[48px] scale-150 md:h-[64px] md:w-[64px]">
              <Image
                src={
                  index === 0
                    ? mysticEssenceElixir
                    : index === 1
                    ? ascencionElixir
                    : `${IFPS_URL}${template?.immutable_data?.img}`
                }
                objectFit="contain"
                layout="fill"
                alt={template?.immutable_data?.name || "Potion Image"}
              />
            </div>
          </div>
          <div className="flex items-center justify-center rounded-xl bg-gray-500">
            <div className="w-full rounded-xl border-2 border-black px-4 text-center">
              {amount || 0}
            </div>
          </div>
        </div>
      ),
    );
  };

  if (!userInventory) {
    // TODO: Skeleton Loading
    return (
      <div
        className={
          className + " loading-circle !h-[75px] !w-[75px] after:hidden"
        }
      ></div>
    );
  }

  return (
    <div className={className}>
      {zombieTokenBalanceIsLoading ? (
        <div className="loading-circle !h-[75px] !w-[75px] after:hidden"></div>
      ) : (
        <div>
          <div className=" items-center justify-center rounded-full bg-gray-900 p-4">
            <div className="relative h-[48px] w-[48px] md:h-[64px] md:w-[64px]">
              <Image
                src={"/image/icons/zbc.png"}
                objectFit="contain"
                layout="fill"
                alt={"Zombie Coin Token"}
              />
            </div>
          </div>
          <div className="flex items-center justify-center rounded-xl bg-gray-500">
            <div className="w-full rounded-xl border-2 border-black px-4 text-center">
              {parseFloat(zombieTokenBalance?.[0]?.balance ?? 0).toFixed(0)}
            </div>
          </div>
        </div>
      )}
      {renderAssets()}
    </div>
  );
};
