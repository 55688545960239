import { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { FaDroplet, FaHeart, FaPersonRunning } from "react-icons/fa6";

import { ITurn, PlayerOne } from "../../../../@types/socket";
import { useBattle } from "../../../../hooks/useBattle";
import { useCurrentUser } from "../../../../hooks/useCurrentUser";
import { useGame } from "../../../../hooks/useGame";
import { useSocket } from "../../../../hooks/useSocket";
// import useSound, { GameActions } from "../../../../hooks/useSound";
import useSound, { GameActions } from "../../../../hooks/useSound";
import { ConfimationModal } from "../../../Modals/ConfirmationModal";
import { ActionsBattleButtons } from "../../ActionsBattleButtons";
import { AtributeBar } from "../../BattleComponents/AtributeBar";
import { BattleCountdown } from "../../BattleComponents/BattleCountdown";
import {
  PlayersAttributes,
  PlayersAttributesMobile,
} from "../../BattleComponents/PlayersAttributes";
import { RoundCounter } from "../../BattleComponents/RoundCounter";
import { CountWins } from "../../CountWins";
import { GameLogs } from "../../GameLogs";

export const BattleStage = () => {
  const { initialRoundData, activeRoundData, activeTurn } = useBattle();
  const { roundData, setStage } = useGame();
  const { currentUser } = useCurrentUser();
  const { gameSound } = useSound();
  const {
    handleSurrenderBattleAction,
    handleSocketBattleAction,
    handleEndTurn,
    getCurrentDate,
  } = useSocket();

  const [user, setUser] = useState<PlayerOne | null>(null);
  const [opponent, setOpponent] = useState<PlayerOne | null>(null);
  const [disabledButtons, setDisabledButtons] = useState(false);
  const [confirmFn, setConfirmFn] = useState<(() => void) | null>(null);

  useEffect(() => {
    gameSound({ typeAction: GameActions.start_match });
  }, []);

  const handleBattleAction = (type: string) => {
    if (type === "flee") {
      handleSurrenderBattleAction();
    }

    if (type === "defend") {
      const defenseCooldown = user?.details?.pet?.defenseCooldown || 0;
      const isBreak = defenseCooldown > 0;
      if (isBreak) {
        return toast.error(
          `You need to wait ${defenseCooldown} turns to defend`,
        );
      }
    }

    handleSocketBattleAction(type.toLowerCase());
  };

  const { stage } = useGame();

  useEffect(() => {
    if (initialRoundData?.data?.players) {
      const players = Object.values(initialRoundData?.data?.players);
      players.forEach(players => {
        if (currentUser === players?.player) {
          setUser(players);
        } else setOpponent(players);
      });
    }
  }, [currentUser, initialRoundData, roundData]);

  const createdAtTurn =
    roundData?.data?.game?.rounds
      ?.find(item => item.isActive)
      ?.turns.find(item => item.isActive)?.updatedAt || "";
  const date = new Date(createdAtTurn);
  const currentDate = getCurrentDate().getTime();

  const difference = ((currentDate - date.getTime()) / 1000) >> 0;
  const timer = 25 - difference;

  const prevTurn = useMemo(() => {
    let turn: ITurn | undefined;
    if (stage === "battle") {
      const findingPrevTurn = activeRoundData?.turns?.find(
        turn => turn?.turn === activeTurn?.turn - 1,
      );
      if (!findingPrevTurn) {
        if (activeRoundData) {
          const lastRound = roundData?.data?.game?.rounds?.find(
            round => round?.round === activeRoundData?.round - 1,
          );
          if (lastRound) {
            const lastTurnWithActionsLength = lastRound?.turns?.filter(
              turn => turn?.actions?.length > 0,
            );
            turn =
              lastTurnWithActionsLength[lastTurnWithActionsLength.length - 1];
          }
        }
      } else {
        turn = findingPrevTurn;
      }
    } else {
      const findingPrevTurn = activeRoundData?.turns?.find(
        turn => turn?.turn === activeTurn?.turn,
      );
      turn = findingPrevTurn;
    }

    if (turn) {
      return turn;
    }

    return undefined;
  }, [activeTurn]);

  useEffect(() => {
    setDisabledButtons(false);
  }, [activeTurn?.turn]);

  useEffect(() => {
    if (stage !== "battle") {
      setDisabledButtons(true);
    }
  }, [stage]);

  return (
    <>
      {!user && !opponent ? (
        <div className="flex min-h-screen flex-col items-center justify-center gap-4">
          <div className="loading-circle"></div>
          <div className="gamebtn" onClick={handleSurrenderBattleAction}>
            Abandon Match
          </div>
        </div>
      ) : (
        <>
          <DesktopLayout
            disabledButtons={disabledButtons}
            setDisabledButtons={setDisabledButtons}
            prevTurn={prevTurn}
            activeRoundData={activeRoundData}
            activeTurn={activeTurn}
            handleBattleAction={handleBattleAction}
            handleEndTurn={handleEndTurn}
            handleSurrenderBattleAction={handleSurrenderBattleAction}
            initialRoundData={initialRoundData}
            opponent={opponent}
            roundData={roundData}
            setConfirmFn={setConfirmFn}
            timer={timer}
            user={user}
            endGame={stage.includes("reward") || stage === "reward-draw"}
            setStage={setStage}
          />
          <MobileLayout
            disabledButtons={disabledButtons}
            setDisabledButtons={setDisabledButtons}
            activeRoundData={activeRoundData}
            activeTurn={activeTurn}
            handleBattleAction={handleBattleAction}
            handleEndTurn={handleEndTurn}
            handleSurrenderBattleAction={handleSurrenderBattleAction}
            initialRoundData={initialRoundData}
            opponent={opponent}
            roundData={roundData}
            setConfirmFn={setConfirmFn}
            timer={timer}
            user={user}
            prevTurn={prevTurn}
            endGame={stage.includes("reward") || stage === "reward-draw"}
            setStage={setStage}
          />
        </>
      )}

      <ConfimationModal
        title={
          "You're about to quit this match. You will lose. Do you wish to proceed?"
        }
        btnText={"Quit"}
        fn={confirmFn}
        setFn={setConfirmFn}
      />
    </>
  );
};

const DesktopLayout = ({
  setConfirmFn,
  handleSurrenderBattleAction,
  activeTurn,
  activeRoundData,
  timer,
  roundData,
  initialRoundData,
  opponent,
  user,
  handleBattleAction,
  handleEndTurn,
  prevTurn,
  setDisabledButtons,
  disabledButtons,
  endGame,
  setStage,
}) => {
  const gap = !prevTurn || !prevTurn?.actions?.length ? "gap-2" : "gap-1";
  return (
    <section
      className={`relative  hidden h-full w-full flex-col items-center justify-center text-2xl font-bold lg:flex xl:w-11/12 2xl:w-4/5 ${gap}`}
    >
      <article className="flex w-full items-center justify-center gap-40">
        <aside>
          <button
            className="gamebtn !border-none !bg-[#ff0000da] !text-[20px]"
            onClick={() =>
              endGame
                ? setStage("selection")
                : setConfirmFn(() => () => handleSurrenderBattleAction())
            }
          >
            Quit Match
          </button>
        </aside>
        <div className="flex h-[100px] w-[155px] flex-col items-center rounded-lg bg-[#121212c8] p-2">
          {activeTurn ? (
            <RoundCounter
              round={"" + activeRoundData?.round || "1"}
              turn={activeTurn.turn}
              maxTurn={"10"}
            />
          ) : (
            <div className="loading-circle !h-[100px] !w-[100px] after:hidden" />
          )}

          <div className="flex flex-col">
            <span className={`text-sm`}>Round History:</span>
            <CountWins
              user={user}
              rounds={roundData?.data?.game?.rounds}
              gameData={roundData?.data?.game}
            />
          </div>
        </div>

        <aside className="w-[170px]">
          {!endGame && (
            <BattleCountdown
              start={timer || 25}
              onEndTurn={() => {
                const data = {
                  gameId:
                    roundData?.data?.game?.id ||
                    initialRoundData?.data?.game?.id ||
                    "",
                  turnId: activeTurn.id || "",
                };

                handleEndTurn(data);
              }}
            />
          )}
        </aside>
      </article>

      <section id="opponent-side" className={`relative w-full`}>
        {!opponent ? (
          <div className="loading-circle !h-[100px] !w-[100px] after:hidden" />
        ) : (
          <PlayersAttributes
            player={opponent}
            opponent={user}
            prevTurn={prevTurn}
          />
        )}
      </section>

      <GameLogs
        prevTurn={prevTurn}
        user={user}
        roundData={roundData}
        roundNumber={activeRoundData?.round}
      />

      <section id="user-side" className="relative flex w-full flex-col gap-5">
        {!user ? (
          <div className="loading-circle !h-[100px] !w-[100px] after:hidden" />
        ) : (
          <PlayersAttributes
            player={user}
            opponent={opponent}
            prevTurn={prevTurn}
            isUser
          />
        )}
        <ActionsBattleButtons
          disabledButtons={disabledButtons}
          handleBattleAction={handleBattleAction}
          setDisabledButtons={setDisabledButtons}
          user={user}
          activeTurn={activeTurn}
        />
      </section>
    </section>
  );
};

const MobileLayout = ({
  setConfirmFn,
  handleSurrenderBattleAction,
  activeTurn,
  activeRoundData,
  timer,
  roundData,
  initialRoundData,
  opponent,
  user,
  handleBattleAction,
  handleEndTurn,
  setDisabledButtons,
  disabledButtons,
  prevTurn,
  endGame,
  setStage,
}) => {
  return (
    <section className="relative flex h-full w-full flex-col items-center justify-center gap-5 text-2xl font-bold xs:gap-5 md:gap-2 lg:hidden ">
      <article className="flex w-full items-center justify-center gap-5 md:gap-20">
        <aside className="md-[100px] relative flex h-[120px] w-[155px] flex-col items-center rounded-lg bg-[#121212c8] p-2">
          <button
            className="gamebtn !absolute left-[-10px] w-[32px] !border-none !bg-[#ff0000da] !p-2 !text-base md:!text-xl"
            onClick={() =>
              endGame
                ? setStage("selection")
                : setConfirmFn(() => () => handleSurrenderBattleAction())
            }
          >
            <FaPersonRunning />
          </button>
          <aside className="absolute right-[-10px] !w-[40px] text-center">
            {!endGame && (
              <BattleCountdown
                start={timer || 25}
                minimal
                onEndTurn={() => {
                  const data = {
                    gameId:
                      roundData?.data?.game?.id ||
                      initialRoundData?.data?.game?.id ||
                      "",
                    turnId: activeTurn.id || "",
                  };

                  handleEndTurn(data);
                }}
              />
            )}
          </aside>
          {activeTurn ? (
            <RoundCounter
              round={"" + activeRoundData?.round || "1"}
              turn={activeTurn.turn}
              maxTurn={"10"}
            />
          ) : (
            <div className="loading-circle !h-[100px] !w-[100px] after:hidden" />
          )}
          <div>
            <span className={`text-sm`}>Round History:</span>
            <CountWins
              user={user}
              rounds={roundData.data.game?.rounds}
              gameData={roundData?.data?.game}
            />
          </div>
        </aside>
        <section
          className={`flex h-max flex-col gap-2 rounded-lg bg-[#121212c8] p-3 text-left md:max-w-[190px] md:flex-col`}
        >
          <div className={`relative flex items-center gap-2`}>
            <span className={` text-red-600 `}>
              <FaHeart />
            </span>
            <AtributeBar
              maxAmount={opponent?.details?.pet?.totalHp || 0}
              currentAmount={opponent?.details?.pet?.currentHp || 0}
              color="#2ecc71"
              minimal
            />
          </div>
          <div className={`flex items-center gap-2`}>
            <span className={`text-[#0984e3] `}>
              <FaDroplet />
            </span>
            <AtributeBar
              maxAmount={opponent?.details?.pet?.manaMax || 0}
              currentAmount={opponent?.details?.pet?.currentMana || 0}
              color="#0984e3"
              minimal
            />
          </div>
          <span className="text-center text-sm">{opponent.player}</span>
        </section>
      </article>

      <section id="opponent-side" className={`w-full`}>
        {!opponent ? (
          <div className="loading-circle !h-[100px] !w-[100px] after:hidden" />
        ) : (
          <PlayersAttributesMobile
            player={opponent}
            opponent={user}
            prevTurn={prevTurn}
          />
        )}
      </section>

      <div className="flex max-w-[90%]">
        <GameLogs
          prevTurn={prevTurn}
          user={user}
          roundData={roundData}
          roundNumber={activeRoundData?.round}
        />
      </div>

      <section id="user-side" className="flex w-full flex-col gap-8 md:gap-10">
        {!user ? (
          <div className="loading-circle !h-[100px] !w-[100px] after:hidden" />
        ) : (
          <PlayersAttributesMobile
            disabledButtons={disabledButtons}
            setDisabledButtons={setDisabledButtons}
            player={user}
            opponent={opponent}
            isUser
            buttonAction={handleBattleAction}
            prevTurn={prevTurn}
          />
        )}
      </section>

      <section className="hidden md:block">
        <ActionsBattleButtons
          disabledButtons={disabledButtons}
          setDisabledButtons={setDisabledButtons}
          handleBattleAction={handleBattleAction}
          user={user}
          activeTurn={activeTurn}
        />
      </section>
    </section>
  );
};
