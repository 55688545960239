import { useEffect, useState } from "react";

import { ITurn, PlayerOne } from "../@types/socket";

export const useDamage = ({
  prevTurn,
  player,
  isDealt = false,
}: {
  player: PlayerOne;
  prevTurn?: ITurn | undefined;
  isDealt?: boolean;
}) => {
  const [damageTaken, setDamageTaken] = useState<number>(0);
  const [damageDealt, setDamageDealt] = useState<number>(0);
  const [damagePersisted, setDamagePersisted] = useState<number>(0);

  function getLastActionWithDamage() {
    return prevTurn?.actions?.find(
      action =>
        action.details.includes("damage") &&
        !action.details.includes("took") &&
        player.deck.playerId !== action.playerId,
    );
  }

  function getLastActionDealt() {
    return prevTurn?.actions?.find(
      action =>
        action.details.includes("damage") &&
        !action.details.includes("took") &&
        player.deck.playerId === action.playerId,
    );
  }

  function getDamageTaken(details: string) {
    const textBeforeDamage = details.split("damage")[0];
    const splitTextInParts = textBeforeDamage.split(" ");
    if (!splitTextInParts) return;
    const wordContainsDamage = splitTextInParts[splitTextInParts.length - 2];
    const damageTaked = Number(wordContainsDamage);
    return damageTaked;
  }

  useEffect(() => {
    if (prevTurn) {
      const prevDamageActionDetails = getLastActionWithDamage()?.details;
      const damageTaked = prevDamageActionDetails
        ? getDamageTaken(prevDamageActionDetails)
        : 0;

      const prevDamageDealtActionDetails = getLastActionDealt()?.details;
      const damageDealt = prevDamageDealtActionDetails
        ? getDamageTaken(prevDamageDealtActionDetails)
        : 0;

      if (damageTaked) {
        setDamageTaken(damageTaked);
        setDamagePersisted(damageTaked);
        setTimeout(() => {
          setDamageTaken(0);
        }, 3000);
      }

      if (damageDealt) {
        setDamageDealt(damageDealt);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevTurn?.turn]);

  return { damageTaken, damagePersisted, damageDealt };
};
